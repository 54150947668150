import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_parent(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_parent">父母移民</a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/familyReunion">親屬類移民（家庭團聚）</a>
          </h1>
          <h3>父母移民</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>申請父母移民簽證應符合以下條件：</p>
      <ul>
        <li>您子女是澳大利亞公民、永久居民或合資格的新西蘭公民；</li>
        <li>您子女定居在澳大利亞至少居住滿兩年並且有能力為您擔保；</li>
        <li>
          您必須保證平衡至少半數或以上的子女長久居住在澳大利亞或您的子女中居住在澳大利亞比居住在其他任何國家要多；
        </li>
        <li>您必須符合有關的健康和品行要求；</li>
        <li>
          若您申請&ldquo;年邁&rdquo;類別簽證必須年滿 66 岁或以上在澳洲境内。
        </li>
      </ul>
      <p>申請人可申請以下其中一種類別的父母類移民簽證：</p>
      <ul>
        <li>普通父母移民 Parent（103）</li>
        <li>贡献付費類父母移民Contributory Parent（143）</li>
        <li>贡献付費類父母移民臨時簽證（173）</li>
        <li>贡献付費類年邁父母居住簽證（864）</li>
        <li>贡献付費類年邁父母移民臨時簽證（884）</li>
      </ul>
      <p>
        移民部最新公布的普通父母移民 2014 年 9 月 25
        日重關接受新的申請，其排队等待审理时间约三十年。贡献付费类父母移民申请的等待审理时间约为三年。在等待期間申請人可申请探亲旅游签证不受影响。
      </p>
      <h3>擔保父母（三或五年臨居）（870）Sponsored Parent</h3>
      <p>
        適用於父母希望能與子女長时间團聚。該簽證 2019 年 7 月 1
        日開啟，無需家庭平衡測試。每次簽證可訪問澳大利亞分三（3）或五（5）
        年，可多次申請簽證訪問澳大利亞累计最長期限為 10
        年。無澳大利亞工作許可。申請此簽證之前，必須有一個經過批准的父母擔保人。在線申請此簽證，在獲擔保批准後
        6 個月內或 60 天內（如獲得在澳大利亞境內的許可）提交簽證申請。
      </p>
      <p>申請簽證應符合以下條件：</p>
      <ul>
        <li>您的子女年滿 18 歲澳大利亞公民、永居或合資格新西蘭公民；</li>
        <li>您子女定居在澳大利亞至少居住滿兩年；</li>
        <li>您子女擔具有能力為您擔保其年薪达纳税收入额度；</li>
        <li>您必須符合有關的健康和品行要求；</li>
        <li>您在澳大利亞期間擁有或有足夠的資金生活；</li>
        <li>您在澳大利亞期間維持適合的医疗保险；</li>
        <li>通過真實性審核您在澳大利亞臨時居留。</li>
      </ul>
      <p>註明：持有此簽證期间不允許申請貢獻付費類父母移民。</p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage header="父母移民" subHeader="Parent visa">
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
