import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_186(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_186">雇主提名移民（永居）（186）ENS</a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/employer_visa_pr">雇主提名移民（永居）</a>
          </h1>
          <h3>雇主提名移民（永居）（186）ENS</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        雇主提名移民是允許澳大利亞雇主向海外招聘僱傭技術人員填補緊缺的職位。持有這簽證可在澳大利亞永久工作和居留。该签证二个途径：
      </p>
      <p>
        <strong>A) 直接移民（Direct Entry）</strong>
      </p>
      <ul>
        <li>在有效職業表（MLTSSL）中提名適合的職業並通過評估。</li>
      </ul>
      <p>
        <strong>B) 臨時簽證（482）转永居（TR Transitional）</strong>
      </p>
      <ul>
        <li>爲澳洲擔保雇主在緊缺職業属（MLTSSL）工作滿三年。</li>
      </ul>
      <p>&nbsp;</p>
      <p>雇主必須符合滿足以下的條件：</p>
      <ul>
        <li>合法注冊具良好生意經營記錄和遵守移民法规；</li>
        <li>履行支付雇員該職位最低工資、工資稅、退休金等；</li>
        <li>負責澳洲人技能培訓基金（SAF）徵收缴费。</li>
      </ul>
      <p>聘請職位必需滿足以下條件：</p>
      <ul>
        <li>須在有效雇主提名職業表（MLTSSL）或（STSOL）；</li>
        <li>聘用期至少三（3）年或以上全職職位；</li>
        <li>符合澳洲勞工法規及該職位最低工資要求.；</li>
        <li>企業能够證明真實需要有償僱員。</li>
      </ul>
      <p>申請人（海外雇員）的要求滿足以下條件：</p>
      <ul>
        <li>年齡需在 18 至 44 週歲内（如不属豁免）；</li>
        <li>具有相等澳大利亞大專或以上學曆掌握技能；</li>
        <li>
          具有英語語言能力雅思 IELTS 成績 4 項每個测试 <strong>6</strong>{" "}
          分或以上， 或成績對等的職業 OET，托福 TOEFL iBT，皮爾遜 PTE，劍橋CAE
          的學術類英語考試；
        </li>
        <li>三年全職或六年兼职以上提名職位属緊缺職業密切相關經驗。</li>
      </ul>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="雇主提名移民（永居）（186）ENS"
        subHeader="Employer Nomination Scheme (subclass 186) visa"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
