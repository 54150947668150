import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Link } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function AnnouncementCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <Card className={classes.root}>
      <CardHeader title="移民动态" subheader="IMMIGRATION ANNOUNCEMENT"></CardHeader>

      <CardContent>
        <Link href="/About">
          <Typography variant="h7">
            商业创新移民（临居）(188A)
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
            投资者移民（临居）(188B)
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
            商业创新移民（永居）(888A)
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
          投资者移民（永居）(888B)
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
          商业人才移民（永居）（132）
            <br></br>
          </Typography>
        </Link>

        <div style={{ display: "flex" }}>
            <Link href="/About" style={{ marginLeft: "auto" }}>
              <Typography variant="h7">
                ... 更多商业移民信息
                <br></br>
              </Typography>
            </Link>
        </div>
      </CardContent>
    </Card>
  );
}
