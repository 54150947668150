import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_child(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_child">子女移民</a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/familyReunion">親屬類移民（家庭團聚）</a>
          </h1>
          <h3>子女移民</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>澳大利亞子女類移民（包括被領養兒童）有以下 4 類的簽證：</p>
      <ul>
        <li>
          <strong>子女 Child</strong>（境外 101 或境内 802）
        </li>
      </ul>
      <p>
        完全依賴于其父母照顾的亲生或再婚繼子女或被領養的，年龄 18 歲以下（18 至
        25
        歲全日制在校生，如有傷殘而無工作能力則無年齡限制）的子女申請人，由其父母为擔保人是澳大利亞公民或永久居民或有資格的新西蘭公民。
      </p>
      <ul>
        <li>
          <strong>收養子女 </strong>
          <strong>Adoption</strong>
          <strong>Child</strong>（102）
        </li>
      </ul>
      <p>
        收養父母是澳大利亞公民或永久居民或有資格的新西蘭公民的並為擔保人，他們已收養或正在辦理收養海外年齡小於
        18 歲的孩子（申請時未滿 18 周歲但決定批准簽證時滿 18 歲不符合資格）。
      </p>
      <ul>
        <li>
          <strong>孤兒亲属 </strong>
          <strong>Orphan Relative</strong>（境内 837 或境外 117）
        </li>
      </ul>
      <p>
        年齡小於 18 歲，由他們的親屬如：兄弟姊妹、祖父母，姨舅叔姑或同等关系 18
        歲以上澳大利亞公民、永久居民、或合資格的新西蘭公民的作擔保的
        <strong>孤兒 </strong>（父母無法照顧例如已去世）。
      </p>
      <ul>
        <li>
          <p>
            <strong>依賴抚养子女 DependentChild</strong>（445）
          </p>
        </li>
      </ul>
      <p>
        持有配偶或情侶臨時簽證（309 或 820
        子類）的親生或再婚繼子女或被領養的孩子，年齡小於 18
        歲，否則滿足以下條件之一：
      </p>
      <ul>
        <li>
          <p>財務依賴於持有配偶或情侶臨時簽證的父親或母親；</p>
        </li>
        <li>
          <p>由於身體或精神功能的全部或部分喪失而無能力工作。</p>
        </li>
      </ul>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage header="子女移民" subHeader="Child visa">
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
