import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import matrix from "../../assets/visa_188_matrix.jpg";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function Visa_188(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_188">商業創新和投資（臨居）（188）</a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/businessInnovationAndInvestment">澳洲商業移民</a>
          </h1>
          <h3>商業創新和投資（臨居）（188）</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        <span lang="en-US">
          <strong>基本要求</strong>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span lang="en-US">必須由州或地區政府擔保；</span>
          </p>
        </li>
        <li>
          <p>
            必須提交移民意向（<strong>EOI</strong>
            <span lang="en-US">）获递交签证申请的邀请；</span>
          </p>
        </li>
        <li>
          <p>
            <span lang="en-US">
              有真實意圖需要在澳大利亞進行合規投資項目或發展經營管理；
            </span>
          </p>
        </li>
        <li>
          <p>
            18歲或以上申請人有實用英文水平或願支付第&nbsp;2
            <span lang="en-US">期簽證申請費；</span>
          </p>
        </li>
        <li>
          <p>
            每個申請人通過移民體檢和在&nbsp;16
            <span lang="en-US">歲後無任何不良違法記錄。</span>
          </p>
        </li>
      </ul>
      <ol type="A">
        <li>
          <p>
            <strong>商業創新&nbsp;</strong>
            <strong>(Business</strong>
            <strong>Innovation)</strong>
          </p>
        </li>
      </ol>
      <ol type="A">
        <ul>
          <li>
            <p>
              55
              <span lang="en-US">
                歲以下（除非申請人能爲澳大利亞帶來特殊的經濟利益）；
              </span>
            </p>
          </li>
          <li>
            <p>
              邀請申请前必須取得測試總分至少&nbsp;<strong>65</strong>
              <span lang="en-US">（参考《評分錶》）：</span>
            </p>
          </li>
          <li>
            <p>
              合法擁有可轉入澳大利亞企業及個人淨資産&nbsp;<strong>150</strong>
              <span lang="en-US">萬澳幣；</span>
            </p>
          </li>
          <li>
            <p>
              過去&nbsp;4年中&nbsp;2年&nbsp;1或&nbsp;2個主要企業達年營業額&nbsp;
              <strong>75</strong>
              <span lang="en-US">萬澳幣；</span>
            </p>
          </li>
          <li>
            <p>
              擁有至少&nbsp;1
              <span lang="en-US">個自主企業的股份以下之一的百分比：</span>
            </p>
            <ul>
              <li>
                <p>
                  51%和每年營業額低于於&nbsp;40
                  <span lang="en-US">萬澳幣；或</span>
                </p>
              </li>
              <li>
                <p>
                  30%和每年營業額不低於&nbsp;40
                  <span lang="en-US">萬澳幣；或</span>
                </p>
              </li>
              <li>
                <p>
                  10%<span lang="en-US">上市公司股份。</span>
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span lang="en-US">有成功的高層經營管理企業的業績。</span>
            </p>
          </li>
        </ul>
      </ol>
      <ol start="2" type="A">
        <li>
          <p>
            <strong>投資者&nbsp;</strong>
            <strong>(Investor)</strong>
          </p>
        </li>
      </ol>
      <ol type="A">
        <ul>
          <li>
            <p>
              55
              <span lang="en-US">
                歲以下（除非申請人能爲澳大利亞帶來特殊的經濟利益）；
              </span>
            </p>
          </li>
          <li>
            <p>
              邀請申请前必須取得測試總分至少&nbsp;6<strong>5</strong>
              <span lang="en-US">（参考《評分錶》）：</span>
            </p>
          </li>
          <li>
            <p>
              願意投入&nbsp;<strong>250</strong>
              <span lang="en-US">萬澳幣澳大利亞合規的投資項目；</span>
            </p>
          </li>
          <li>
            <p>
              邀請申请前二年合法擁有企業及個人的淨資産&nbsp;<strong>250</strong>
              <span lang="en-US">萬澳幣；</span>
            </p>
          </li>
          <li>
            <p>
              過去&nbsp;<strong>5</strong>
              <span lang="en-US">
                年中最少一年您或配偶，或您和配偶合并拥有：
              </span>
            </p>
            <ul>
              <li>
                <p>
                  直接管理投資額達&nbsp;150<span lang="en-US">萬澳幣；或</span>
                </p>
              </li>
              <li>
                <p>
                  直接高層管理經營擁有&nbsp;10%
                  <span lang="en-US">企業股份的生意；</span>
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span lang="en-US">
                具有至少三年高层企業經營或投資的管理技能及經驗的記錄。
              </span>
            </p>
          </li>
        </ul>
      </ol>
      <ol start="3" type="A">
        <li>
          <p>
            <strong>重大投資者&nbsp;</strong>
            <strong>(Significant-Investor)</strong>
          </p>
        </li>
      </ol>
      <ol type="A">
        <ul>
          <li>
            <p>
              <span lang="en-US">無年齡限制和無需測試計分；</span>
            </p>
          </li>
          <li>
            <p>
              拥有合法獲取支配的淨資產&nbsp;<strong>500</strong>
              <span lang="en-US">萬澳幣在澳大利亞投資；</span>
            </p>
          </li>
          <li>
            <p>
              邀請申请前二年合法擁有企業及個人的淨資産&nbsp;500
              <span lang="en-US">萬澳幣；</span>
            </p>
          </li>
          <li>
            <span lang="en-US">
              持臨居簽證期間在澳大利亞擔保州地區居住和進行商業活動；
              <br />
              <br />
            </span>
          </li>
          <li>
            持臨居簽證期间您将在澳大利亞平均每年居住 40
            天或您配偶持臨居簽證平均每年居住 180 天。
          </li>
        </ul>
      </ol>
      <ol type="A" start="4">
        <li>
          <p lang="en-AU">
            <span>
              <span>
                <span lang="en-US">
                  <span>
                    <span>
                      <b>企業家 </b>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span>
                    <b>(Entrepreneur)</b>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li>
              <p lang="en-AU">
                <span>
                  <span>
                    <span>55</span>
                  </span>
                  <span></span>
                </span>
                <span>
                  <span>
                    <span lang="en-US">
                      <span>歲以下，無需測試計分；</span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p lang="en-AU">
                <span>
                  <span>
                    <span lang="en-US">
                      <span>
                        <span>具有英語語言能對等於雅思 </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>IELTS</span>
                  </span>
                  <span></span>
                </span>
                <span>
                  <span>
                    <span lang="en-US">
                      <span>
                        <span>成绩 </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>4</span>
                  </span>
                  <span></span>
                </span>
                <span>
                  <span>
                    <span lang="en-US">
                      <span>
                        <span>項每個 </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>6</span>
                  </span>
                  <span></span>
                </span>
                <span>
                  <span>
                    <span lang="en-US">
                      <span>分；</span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p lang="en-AU">
                <span>
                  <span>
                    <span lang="en-US">
                      <span>
                        <span>
                          須獲得州或領地政府機構提名認可您提議的發展創業理念；
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p lang="en-AU">
                <span>
                  <span>
                    <span lang="en-US">
                      <span>
                        在澳大利亞從事創業實體將創新理念如何实现產品或服務的商業化，或企業業務的發展；
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p lang="en-AU">
                <span>
                  <span>
                    <span lang="en-US">
                      <span>
                        企業家活動不得涉及任何類別的住宅房地產、勞動力僱傭、購買澳大利亞現有企業、或連鎖經營權。
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
        </li>
      </ol>
      <p lang="en-AU">
        <span>
          <span>
            <span lang="en-US">
              <b>商業創新和投資（</b>
              <span>
                <span>
                  <b>臨居 </b>
                </span>
              </span>
            </span>
          </span>
        </span>
        <span>
          <span>
            <span>
              <b>188</b>
            </span>
          </span>
          <span>
            <b>A</b>
          </span>
          <span>
            <span></span>
          </span>
        </span>
        <span>
          <span>
            <span lang="en-US">
              <b>或 </b>
            </span>
          </span>
        </span>
        <span>
          <span>
            <b>B</b>
          </span>
        </span>
        <span>
          <span>
            <span lang="en-US">
              <b>）</b>
              <span>
                <span>
                  <b>評分錶</b>
                </span>
              </span>
              <span>
                <b>（</b>
              </span>
              <span>
                <b>總分 </b>
              </span>
            </span>
          </span>
        </span>
        <span>
          <span>
            <span>
              <b>65</b>
            </span>
          </span>
        </span>
        <span>
          <span>
            <span lang="en-US">
              <span>
                <b>）</b>
              </span>
            </span>
          </span>
        </span>
      </p>
      <img src={matrix} style={{ width: "100%" }}></img>
      <p>
        <span>
          <strong>注明：</strong>帶有&ldquo;
        </span>
        *&rdquo;<span>是必須計算積分的項目，澳大利亞幣兌換人民幣（</span>1
        <span>：</span>5<span>）。</span>
      </p>
      <ol>
        <li>
          <span>商業創新臨居（</span>A<span>）可申請延期一（</span>1
          <span>）次有效二（</span>2<span>）年。</span>
        </li>
        <li>
          <span>重大投資者 臨居（</span>C<span>）可申請延期二（</span>2
          <span>）次共有效期四（</span>4<span>）年。</span>
        </li>
        <li>
          <span>持臨居簽證的副申請人可換為永居簽證的主請人。</span>
        </li>
      </ol>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="商業創新和投資（臨居）（188）"
        subHeader="Business Innovation and Investment (Provisional) visa (subclass 188)"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
