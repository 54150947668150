import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_494(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_494">偏远地區雇主擔保（臨居）（494）- SESR</a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/employer_visa_tr">雇主提名移民（臨居）</a>
          </h1>
          <h3>偏远地區雇主擔保（臨居）（494）- SESR</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        允許澳大利亞指定地區的商業機構雇主擔保海外人員從事緊缺職業最長五（5）年臨時工作。申請人（雇員）的要求滿足以下條件：
      </p>
      <ul>
        <li>
          <p>
            年齡需在 18 至 <strong>44</strong> 週歲内下（如不属豁免）；
          </p>
        </li>
        <li>
          <p>在有效職業表（MLTSSL）或（ROL）中提名適合的職業；</p>
        </li>
        <li>
          <p>由雇主擔保提名職位（在有效緊缺職業表裡）；</p>
        </li>
        <li>
          <p>具有技能、學曆、至少三（3）年緊密相關聯的工作經驗；</p>
        </li>
        <li>
          <p>需要通过相關机构对提名職業的技術評估（如不属豁免）；</p>
        </li>
        <li>
          <p>具备資格領澳洲法规职业牌照或注册成员（如職位要求）；</p>
        </li>
        <li>
          <p>
            具有英文語言能力雅思 IELTS 成績 4 項每個测试 6 分或以上，
            或成績對等的職業英文 OET，托福 TOEFL iBT，皮爾遜 PTE， 劍橋CAE
            的學術類英語考試；
          </p>
        </li>
        <li>
          <p>領取該職位市场最低年薪纳税收入。</p>
        </li>
      </ul>
      <p>
        雇主擔保必需滿足的條件：聘請職位必需滿足的條件；及工作簽證上有的條件規定，参考上述的技術职业紧缺工作<a href="visa_482">（臨居）（482）-
        TSS</a> 所列規定條款一致。
      </p>
      <p>
        成功的申請人獲五年臨居簽證，在指定偏遠地區居住滿並相當於全職工作三（3）年，每年應徵稅收入達到或超過特定起點，可申請技術偏遠地區永居簽證（191
        ）。該簽證將於 2022 年 11 月 16
        日開啟。申請時無需擔保無需測試計分。獲該永居簽證後仍需在指定偏遠地區生活至少二（2）年。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="偏远地區雇主擔保（臨居）（494）- SESR"
        subHeader="Skilled Employer Sponsored Regional (Provisional) visa (subclass 494)"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
