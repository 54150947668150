import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import img from '../../assets/advantage3.png';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  media: {
    height: 260,
  },
});

export default function CoreAdvantageCard3() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={img}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            澳洲本地顾问
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          “诚信、专业、快捷、信誉为本、客户至上”是我们一直秉承的办事宗旨。高水准的专业服务和贴心完善的后继服务，令您省时放心。

鹏迅专家亲自为您度身设计策划移民留学之路，是您达成愿望的最佳保障。自公司创办以来，已帮助了众多的家庭成功移民澳洲，也为众多的学子实现了留学的梦想。

我们关注您到澳后安家落户、创办公司、买卖生意、管理生意、进出口贸易、转永居、就业安排、子女教育等全方位的需求，随时为您提供服务，全力协助您尽快融入当地社会，以适应和分享澳洲优越的生活和环境。
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
