import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function AboutUs() {
  const classes = useStyles();

  return (
    <>
      <ContentPage header="公司简介" subHeader="ABOUT US">
        <Card className={classes.root}>
          <CardContent>
            <Typography>
            <p>澳大利亚鹏迅移民留学顾问是一家专业办理澳洲移民，澳洲留学的顾问公司。总部设在澳洲悉尼，网络遍布澳洲各大城市，并在广州设立了中国业务联络处。</p>
<p>鹏迅移民留学顾问是澳大利亚移民局认可的电子签证机构。多名澳大利亚正式注册的移民代理精心为大批中国留学生设计了最佳的留学方案。也已成功地为大批中国同胞办理了澳大利亚各类签证，包括各类商业移民，技术移民，雇主提名移民，各类家庭亲属移民和各类短期签证。</p>
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            title="澳洲留学"
          ></CardHeader>
          <Divider variant="middle" />

          <CardContent>
            <Typography>
            <p>留学部由澳洲博士，教育专家赵博士主理。赵博士十多年前获得澳洲博士学位，曾在昆士兰大学，莫纳什大学 (Monash University)，格里菲斯大学(Griffith University)和卧龙岗大学(Wollongong University)等澳洲著名大学工作，对澳洲的教育制度有较深入的研究。同时，赵博士精通留学签证的各种要求和留学移民政策的最新动向，是您顺利获得留学签证，实现留学+移民澳洲梦想的信心保证。由赵博士带领的留学教育专家团队，竭诚为您精心设计留学+移民方案。</p>    
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            title="澳洲移民"
          ></CardHeader>
          <Divider variant="middle" />

          <CardContent>
            <Typography>
            <p>鹏迅移民顾问是一支由澳洲资深注册移民代理、投资专家、会计师等专业人士构成的精英队伍。我们的移民代理和专业顾问有权直接进入澳大利亚移民局的移民法、移民条例和移民政策数据库查询有关移民留学政策的详细资料和最新动向，为广大客户提供第一手的准确资料。助您实现移民澳洲的梦想。</p>
            <p>“诚信、专业、快捷、信誉为本、客户至上”是我们一直秉承的办事宗旨。高水准的专业服务和贴心完善的后继服务，令您省时放心。</p>
<p>鹏迅专家亲自为您度身设计策划移民留学之路，是您达成愿望的最佳保障。自公司创办以来，已帮助了众多的家庭成功移民澳洲，也为众多的学子实现了留学的梦想。</p>
<p>我们关注您到澳后安家落户、创办公司、买卖生意、管理生意、进出口贸易、转永居、就业安排、子女教育等全方位的需求，随时为您提供服务，全力协助您尽快融入当地社会，以适应和分享澳洲优越的生活和环境。 </p>
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            title="鹏迅移民留学 —— 澳大利亚的绿色通道！"
            style={{color: '#8cdb2b'}}
            ></CardHeader>
          <Divider variant="middle" />

          <CardContent>
            <Typography>
            <table width="98%">
<tbody><tr><td width="50%">
<div id="1" name="1"><font size="+1"> <b> 移民一条龙服务项目： </b></font></div>
<ol>
<font size="2">
<li>移民资格评估</li>
<li>专业指导移民类别申请</li>
<li>代填各类移民申请表格</li>
<li>代办移民签证</li>
<li>代订机票</li>
<li>安排澳洲机场接机服务（收取成本费）</li>
<li>安排澳洲住宿服务（收取成本费）</li>
<li>行前准备指导</li>
<li>澳洲生活指导（电话免费咨询）</li>
</font>
</ol>
</td>
<td width="50%">
<font size="+1"> <b> 留学一条龙服务项目：</b></font>
<ol>
<font size="2">
<li>留学资格评估</li>
<li>专业指导择校和择科申请</li>
<li>代办大学、中学、小学的入学申请</li>
<li>代办留学签证</li>
<li>代订机票</li>
<li>安排澳洲机场接机服务（收取成本费）</li>
<li>安排澳洲住宿服务（收取成本费）</li>
<li>行前准备指导</li>
<li>澳洲生活指导（电话免费咨询）</li>
<font></font></font></ol><font size="2"><font>
</font></font></td>
</tr></tbody></table>

<font size="+1"> <b> 各类单项服务: </b></font>
<p></p><font size="2">
<b> 1、	代办大学、中学、小学的入学申请</b> <p></p>
<ul>
<li>提供澳洲各大学、中学、小学的具体情况。如学校概况、入学要求、课程设置、开学日期及学费等。</li>
<li>指导选择合适的学校和课程/专业</li>
<li>代填入学申请表及入学申请的其他相关资料</li>
<li>向学校递交申请人的材料等有关入学申请手续  </li>
</ul>

<b> 2、	代办各类澳洲签证</b> <p></p>
<ul><li>代办澳洲移民、留学、探亲、旅游等签证</li></ul>

<b> 3、	各类文件的翻译</b> <p></p>
<ul><li>代办各类文件的中译英、英译中服务</li></ul>

<b> 4、	代填各类申请表格</b> <p></p>
<ul><li>代填澳洲移民、留学、探亲、旅游等的各类申请表格</li></ul>
<b> 5、	接机安排</b> <p></p>
<ul><li>办理澳洲机场的接机服务</li></ul>
<p></p>
<b> 6、	住宿安排</b> <p></p>
<ul><li>已取得澳洲移民、留学、探亲、旅游等签证的人士，如需安排住宿，我公司代办澳洲房屋租、购服务</li></ul>
<p></p>
<b> 7、	代订机票 </b> <p></p>
<ul><li>代订中国各地到澳洲的单程或双程机票</li></ul>
</font><p></p>
<br></br>
地址：Suite 202, Eastwood Centre Office Tower, 160 Rowe Street, Eastwood, NSW 2122<p></p>
电话： 61 2 8065 0864<p></p>
传真： 61 2 8084 1629 <p></p>
手机： 61 432 827 628    <p></p>   
电邮： info@pengsun.net.au <p></p> 

            </Typography>
          </CardContent>
        </Card>
      </ContentPage>
    </>
  );
}
