import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "./skilled_visa_matrix";
import Visa_189 from "./visa_189";
import Visa_190 from "./visa_190";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
});

export default function SkilledMigrationPR(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const content = () => (
    <>
      <p>澳大利亞技術移民申請人或配偶必须滿足以下基本條件：</p>
      <p>
        <strong>職業</strong>
        ：申請人需在有效職業表中提名適合的職業並通過評估；
      </p>
      <p>
        <strong>年齡</strong>：申請人年齡需必须是 18 至 44 週歲内；
      </p>
      <p>
        <strong>英語</strong>：申請人應具有"類利英語"水平（雅思成绩 6
        分或以上）；
      </p>
      <p>
        <strong>學曆</strong>：申請人需持有大專以上畢業證書；
      </p>
      <p>
        <strong>工作</strong>：申請人過去 10
        年中有至少在澳大利亚有一年，或其他国家有三年的与您適合提名的職業密切關聯的全職工作經曆。
      </p>
      <p>
        在滿足基本條件的基礎上，技術移民有很多類別，還需通過打分测试系統，滿足通過最低分數線
        65（2018 年 7 月 1 日起）。申請人须提交移民意向（EOI），等獲移民部邀请函
        60 天内須遞交正式签證申請。
      </p>
      <Visa_189 hideHeader></Visa_189>
      <Visa_190 hideHeader></Visa_190>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="澳洲技術類移民（永居）"
        subHeader="Skilled Migration Visa"
      >
        <Card className={classes.root}>
          <h1>澳洲技術類移民（永居）</h1>
          <Typography>{content()}</Typography>
          <SkilledVisaMatrix></SkilledVisaMatrix>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
