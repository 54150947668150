import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import matrix from "../../assets/visa_190_matrix.png";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
});

export default function SkilledVisaMatrix() {
  const classes = useStyles();

  return (
    <>
      <img src={matrix} style={{ width: "100%" }}></img>
    </>
  );
}
