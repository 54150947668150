import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import SkilledMigrationPR from "../skilledMigration/skilledMigrationPR";
import SkilledMigrationTR from "../skilledMigration/skilledMigrationTR";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function VisitorVisaPage() {
  const classes = useStyles();

  return (
    <>
      <ContentPage header="訪客旅遊" subHeader="VISITOR VISA">
        <Card className={classes.root}>
          <h1>訪客旅遊 Visitor Visa（600）</h1>
          <p>
            如果訪澳目的爲旅遊或其他娛樂目的（度假、觀光、探戚訪友），應申請旅遊探親簽證。
            旅遊探親簽證持有人在澳期間不得工作或從事任何商業活動，但允許參加三個月或以內的課程。旅遊探親簽證種類有：
          </p>
          <h3>商務旅游訪問</h3>
          <p>
            短期商務旅游簽證是爲具有真實商務意圖的打算每次在澳大利亞逗留最長不超過
            <strong>三</strong>個月的訪客而設的簽證類別。有效期最長為三年。
          </p>
          <p>申請人必須證明：</p>
          <ul>
            <li>
              他們的個人工作性質和商業背景是與將在澳大利亞展開的商業活動有關聯的；
            </li>
            <li>他們有在澳大利亞進行商務活動的需要；</li>
            <li>他們有足夠的資金以支持其留澳期間的個人花費；</li>
            <li>他們不會在澳從事有薪雇傭或培訓。</li>
          </ul>
          <h3>普通訪客旅游或探親</h3>
          <p>訪客旅遊簽探親證的申請人</p>
          <ul>
            <li>允許停留三個月、六個月或十二個月；</li>
            <li>簽證有效期分別單程或一、三、五、十年內多次往返；</li>
            <li>簽證在澳大利亞駐華領事館簽證處受理；</li>
            <li>申請人訪問澳洲目的必須真實；</li>
            <li>無需要交納保證金自由行。</li>
          </ul>
          <h3>親屬擔保探親旅游</h3>
          <p>
            申請人在澳大利亞有親属；一般允許停留三個月，特殊情況下可允許六或十二個月；
            親属是定居在澳大利亞的公民或永久居民提出申請，
            願意擔保您到澳大利亞進行短期探訪。
          </p>
          <p>
            根據移民法則規定，親屬是指：配偶﹑父母﹑兄弟姐妹﹑子女﹑收養子女﹑阿姨/姑姑﹑舅舅/叔叔﹑侄/外甥﹑祖/外祖父母﹑孫/外孫和相應的&ldquo;繼&rdquo;親及收養親屬。
          </p>
          <p>
            未婚夫（妻）﹑姻親﹑表親﹑朋友和同性配偶不能作擔保人。
            新西蘭公民不能擔保其親屬前往澳大利亞。
          </p>
          <p>
            如果您不符合擔保人資格，您仍可以發函給申請人，並在信中說明您與申請人的關系和表明您能給予申請人的支持限度。申請人可將信函與申請一同提交本辦。
          </p>
          <p>
            符合資格的擔保人必須願意對申請人按期離開澳大利亞作出保證並交納保證金。保證金數額由澳大利亞移民官員根據每個申請不同情況而
            定，金額由每人 5000 澳元至 15000 澳元。
            爲避免擔保人受到處罰，被擔保人必須在簽證過期前離開澳大利亞。
          </p>
        </Card>
      </ContentPage>
    </>
  );
}
