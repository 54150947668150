import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "./skilled_visa_matrix";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_189(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_189">
              獨立技術簽證 (189) Skilled &ndash; Independent
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/skilled_visa_pr">澳洲技術移民（永居）</a>
          </h1>
          <h3>獨立技術簽證 (189) Skilled &ndash; Independent</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        適合在有效的移民技術职業表（MLTSSL）具有認可的提名技術职業的人士，符合上述技術類移民的基本條件。技能獨立（189
        類）的簽證有分兩類：
      </p>
      <ol>
        <li>
          技能獨立
          189（計分測試）；能达測試計分最低分數的人員。參考《澳大利亚技術移民計分测试標準表》。
        </li>
        <li>
          技能獨立
          189（新西蘭）類將給許多新西蘭公民永久居留身份，如果他們提交申請滿足簽證的標準包括以下：
        </li>
      </ol>
      <ul>
        <li className={classes.list}>
          <ul>
            <li>持有特殊類別（444 類）簽證；</li>
            <li>於 2016 年 2 月 19 日或之前開始連續五年常住在澳大利亞；</li>
            <li>過去五年每年收入納稅达稅務局工資額（除非請求豁免）；</li>
            <li>符合健康標準，品格和安全的檢查。</li>
          </ul>
        </li>
      </ul>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="獨立技術簽證 (189) Skilled &ndash; Independent"
        subHeader="Skilled Independent visa (subclass 189)"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
        <SkilledVisaMatrix></SkilledVisaMatrix>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
