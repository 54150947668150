import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_482(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_482">
              技術职业紧缺工作（臨居）（482）- TSS
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/employer_visa_tr">雇主提名移民（臨居）</a>
          </h1>
          <h3>技術职业紧缺工作（臨居）（482）- TSS</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        允許澳大利亞或海外的商業機構擔保海外人員到澳大利亞從事短期緊缺職業最長二（2）年或中长緊缺職業最長四（4）年臨時工作。
      </p>
      <p>申請人（雇員）的要求滿足以下條件：</p>
      <ul>
        <li>
          <p>年齡 18 歲以上；</p>
        </li>
        <li>
          <p>由雇主擔保提名職位（需在有效緊缺職業表裡）；</p>
        </li>
        <li>
          <p>具有技能、學曆、至少二（2）年全职緊密相關聯的工作經驗；</p>
        </li>
        <li>
          <p>部分技工类职業可能需要通过相關机构的技術評估和要求；</p>
        </li>
        <li>
          <p>具备資格獲領澳大利亞職業牌照或註冊成員（如法定適用）；</p>
        </li>
        <li>
          <p>
            具有英文語言能力雅思 IELTS 成績短期緊缺職業的達總分{" "}
            <strong>5</strong> 且每項 <strong>4.5</strong>{" "}
            或长中期緊缺職業的達總分 <strong>5</strong> 每項
            5，或成績對等的職業英文 OET，托福 TOEFL iBT，皮爾遜 PTE，劍橋 CAE
            的學術類英語考試；
          </p>
        </li>
        <li>
          <p>
            領取該職位市场最低年薪收入纳税。
            <br />
            <br />
          </p>
        </li>
      </ul>
      <p>澳大利亞雇主擔保必須符合滿足以下的條件：</p>
      <ul>
        <li>
          <p>合法注冊具良好生意經營記錄和遵守移民法规；</p>
        </li>
        <li>
          <p>負責澳洲人技能培訓基金（SAF ）徵收；</p>
        </li>
        <li>
          <p>雇主直接聘用雇員和日常管理：</p>
        </li>
        <li>
          <p>支付該職位工資（包括工資稅、退休金、保险等）；</p>
        </li>
        <li>
          <p>勞動市場測驗證實聘請海外人員真實需要（如不属豁免）；</p>
        </li>
        <li>
          <p>能证明對澳洲有利益：</p>
          <ul>
            <li>
              <p>引進、使用、創建新的生意；</p>
            </li>
            <li>
              <p>引進、使用、創建新或更新技術；</p>
            </li>
            <li>
              <p>提高技术生产及古管理能力；</p>
            </li>
            <li>
              <p>增加经济效益及为澳洲人创就业机会； 海外雇主需要：</p>
            </li>
          </ul>
        </li>
        <li>
          <p>曆附屬分公司、或連鎖公司、子屬部們；</p>
        </li>
        <li>
          <p>
            在澳洲履行合同的義務或其它生意活動。
            <br />
            <br />
          </p>
        </li>
      </ul>
      <p>聘請職位必需滿足以下條件：</p>
      <ul>
        <li>
          <p>須在有效雇主提名職業表（MLTSSL）或（STSOL）；</p>
        </li>
        <li>
          <p>公司生意经营緊密關係的全職職位；</p>
        </li>
        <li>
          <p>符合所有相關澳洲法律或條件；</p>
        </li>
        <li>
          <p>符合該職位最低的技術要求；</p>
        </li>
        <li>
          <p>符合該職位最低工資要求。</p>
        </li>
      </ul>
      <p>
        <br />
        工作簽證上有以下的條件規定：
      </p>
      <ul>
        <li>
          <p>海外雇員不能擅自停止爲擔保他們的雇主的工作；</p>
        </li>
        <li>
          <p>不允許換成與雇主提名時指定的工種不同的工作；</p>
        </li>
        <li>
          <p>不能同時爲其他人或自己的生意工作；</p>
        </li>
        <li>
          <p>
            有地區政府支持的海外雇員必須在该地區當地工作；如果他們擅自到非邊遠地區工作生活，簽證會被取消。
          </p>
        </li>
      </ul>
      <p>
        <strong>注明：</strong>申請人持有 <strong>482</strong>{" "}
        簽證在澳洲爲擔保雇主工作滿三（3）年後，如滿足條件雇主願意繼續擔保的可申請雇主提名轉<a href="visa_186"><strong>永居</strong>（<strong>186</strong>
        ）</a>
        ，也可根據申請人的實際條件通過其他途徑轉永居如上述技術類移民。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="技術职业紧缺工作（臨居）（482）- TSS"
        subHeader="Temporary Skill Shortage visa (subclass 482)"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
