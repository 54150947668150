import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Link } from '@material-ui/core';
import './material_card.css';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  link: {
  }
});

export default function StudyAbroadCard() {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="留学信息" subheader="STUDY ABROAD"></CardHeader>

      <CardContent>
        <Link className={classes.link} href="/About">
          <Typography variant="h7">
            留学澳洲的基本条件
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳大利亚留学的优势
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          留学常见问题问答
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          申请签证过程中应注意的几个环节
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          海外学生医疗保险
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳大利亚大学星级排名
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          海外学生家属陪读申请须知
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          留学澳洲宜谨慎选择学校和专业
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳洲大学对中国学生高考成绩的看法
            <br></br>
          </Typography>
        </Link>
        <div style={{ display: "flex" }}>
            <Link className={classes.link} href="/About" style={{ marginLeft: "auto" }}>
              <Typography variant="h7">
                ... 更多留学信息
                <br></br>
              </Typography>
            </Link>
        </div>
      </CardContent>
    </Card>
  );
}
