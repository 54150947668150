import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "../skilledMigration/skilled_visa_matrix";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_858(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_858">
              全球人才簽證計劃（GTP）（永居）
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/employer_visa_pr">雇主提名移民（永居）</a>
          </h1>
          <h3>全球人才簽證計劃（GTP）（永居）</h3>
        </>
      );
    }
  };
  const content = () => (
    <>
      {header()}
      <p>
        該計劃吸引未來頂級領域的技術移民到澳大利亞，将帶來自世界各地最佳人才。他们通過轉讓技能促進創新和創造就業機會，為澳大利亞人創造機會。
      </p>
      <p>目前涵蓋 10 個目標领域行業：</p>
      <ol>
        <ul>
          <ul>
            <li>資源（Resources）</li>
            <li>農業食品和農業科技（Agri-food and AgTech）</li>
            <li>電力 （Energy）</li>
            <li>健康產業（Health Industries）</li>
            <li>
              國防、先進製造和航天（Defence, Advanced Manufacturing and Space）
            </li>
            <li>循環經濟（Circular Economy）</li>
            <li>數碼科技（DigiTech）</li>
            <li>基礎設施和旅遊（Infrastructure and Tourism）</li>
            <li>金融服務和金融科技（Financial Services and FinTech）</li>
            <li>教育（Education）</li>
          </ul>
        </ul>
      </ol>
      <p>候選人基本條件是：</p>
      <ul>
        <li>
          在目標领域行業之一中具有頂級高科技专家学者，或者最近能够獲得博士學位或高等級畢業生。
        </li>
        <li>有能力獲得等於或高於&ldquo;公平勞工&rdquo;公布的高收入（目前為</li>
      </ul>
      <p>153,600 澳元）的工資。該數字每年在 7 月 1 日進行調整。</p>
      <ul>
        <li>
          獲得提名人的認可，該提名人在與您相同的領域中具有國家聲譽並且是澳大利亞公民或永久居民，或合格的新西蘭公民；或澳大利亞組織機構。
        </li>
      </ul>

      <h4>傑出人才簽證（858）</h4>
      <p>
        適用於在合格領域具有國際公認的傑出成就的人。您必須在：專業技術，一種運動，藝術，或學術研究，獲得國際認可並取得成就錄。
      </p>
      <p>申請人的成就必須是卓越而傑出的。這意味著：</p>
      <ul>
        <li>
          <p>過去的兩年在您所在領域中很突出；</p>
        </li>
        <li>
          <p>具有在您所在領域中超其他人的卓越能力；</p>
        </li>
        <li>
          <p>在您所在領域中實踐中任何國家都被認為是傑出的；</p>
        </li>
        <li>
          <p>在您所在領域中有持續取得成就的記錄將來不太可能減低；</p>
        </li>
        <li>
          <p>在澳大利亞參與您所在領域得到認可接受具有國際性活动；</p>
        </li>
        <li>
          <p>必須由澳洲您所在領域中负有盛名及资格的人给予您提名；</p>
        </li>
      </ul>
      <p>
        這簽證無年齡要求，未滿 18 歲或者申請時年滿 55
        歲，則體現您必須對澳洲社區特別有利。18
        歲以上的申請人，必須使用英語實用英語功能水平（即相等於雅思成績 4 每項
        4.5 分），否則將需要支付額外費用稱為第二期簽證費（英文培訓）。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="全球人才簽證計劃（GTP）（永居）"
        subHeader="Global Talent Visa Program GTI"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
