import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import AboutUs from "../pages/about/aboutUs";
import contactUs from "../pages/contactUs";
import homePage from "../pages/homePage";
import BusinessInnovationAndInvestmentPage from "../pages/migrations/businessInnovationAndInvestmentPage";
import EmployerSponsoredPage from "../pages/migrations/employerSponsoredPage";
import FamilyReunionPage from "../pages/migrations/familyReunionPage";
import SkilledMigrationPage from "../pages/migrations/skilledMigration";
import StudentVisaPage from "../pages/migrations/studentVisa";

import SkilledMigrationPR from "../pages/skilledMigration/skilledMigrationPR";
import SkilledMigrationTR from "../pages/skilledMigration/skilledMigrationTR";
import Visa_188 from "../pages/bussinessInnovation/visa_188";
import Visa_888 from "../pages/bussinessInnovation/visa_888";
import Visa_189 from "../pages/skilledMigration/visa_189";
import Visa_190 from "../pages/skilledMigration/visa_190";
import Visa_491 from "../pages/skilledMigration/visa_491";
import Visa_485 from "../pages/skilledMigration/visa_485";
import Visa_476 from "../pages/skilledMigration/visa_476";

import EmployerSponsoredPR from "../pages/employerSponsored/employer_visa_pr";
import EmployerSponsoredTR from "../pages/employerSponsored/employer_visa_tr";
import Visa_858 from "../pages/employerSponsored/visa_858";
import Visa_186 from "../pages/employerSponsored/visa_186";
import Visa_482 from "../pages/employerSponsored/visa_482";
import Visa_494 from "../pages/employerSponsored/visa_494";
import Visa_partner from "../pages/familyReunion/partner_visa";
import Visa_parent from "../pages/familyReunion/parent_visa";
import Visa_child from "../pages/familyReunion/child_visa";
import VisitorVisaPage from "../pages/migrations/visitorVisaPage";
import ComingSoon from "../pages/comingSoon";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={homePage} />
      <Route path="/about" component={ComingSoon} />
      <Route path="/aboutUs" component={AboutUs} />
      <Route path="/contactUs" component={contactUs} />

      {/* Routes in Header */}
      <Route path="/familyReunion" component={FamilyReunionPage}></Route>
      <Route
        path="/businessInnovationAndInvestment"
        component={BusinessInnovationAndInvestmentPage}
      ></Route>
      <Route
        path="/employerSponsored"
        component={EmployerSponsoredPage}
      ></Route>
      <Route path="/skilled" component={SkilledMigrationPage}></Route>
      <Route path="/studentVisa" component={StudentVisaPage}></Route>
      <Route path="/visitorVisa" component={VisitorVisaPage}></Route>
      
      {/* Skilled Visa Page */}
      <Route path="/skilled_visa_pr" component={SkilledMigrationPR}></Route>
      <Route path="/skilled_visa_tr" component={SkilledMigrationTR}></Route>

      {/* Employer Sponsored Visa Page */}
      <Route path="/employer_visa_pr" component={EmployerSponsoredPR}></Route>
      <Route path="/employer_visa_tr" component={EmployerSponsoredTR}></Route>

      {/* Visa sub-pages */}
      <Route path="/visa_188" component={Visa_188}></Route>
      <Route path="/visa_888" component={Visa_888}></Route>
      <Route path="/visa_189" component={Visa_189}></Route>
      <Route path="/visa_190" component={Visa_190}></Route>
      <Route path="/visa_491" component={Visa_491}></Route>
      <Route path="/visa_485" component={Visa_485}></Route>
      <Route path="/visa_476" component={Visa_476}></Route>
      <Route path="/visa_858" component={Visa_858}></Route>
      <Route path="/visa_186" component={Visa_186}></Route>
      <Route path="/visa_482" component={Visa_482}></Route>
      <Route path="/visa_494" component={Visa_494}></Route>
      <Route path="/visa_partner" component={Visa_partner}></Route>
      <Route path="/visa_parent" component={Visa_parent}></Route>
      <Route path="/visa_child" component={Visa_child}></Route>
    </Switch>
  );
}
