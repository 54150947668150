import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "./skilled_visa_matrix";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
});

export default function Visa_476(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_476">
              技能認可畢業簽證 (476) Skilled &ndash; Recognised Graduate
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/skilled_visa_tr">澳洲技術移民（臨居）</a>
          </h1>
          <h3>技能認可畢業簽證 (476) Skilled &ndash; Recognised Graduate</h3>
        </>
      );
    }
  };
  const content = () => (
    <>
      {header()}
      <p>
        專爲符合在過去兩（2）年内在認可高等教育機構完成工程學位的畢業生而設的期限
        18
        個月的工作簽證，無需測試計分，無學習或工作條件限制。簽證持有人可隨時根據的實際條件通過其他途徑申請轉永居或臨時技術工作簽證或雇主擔保工作簽證。
      </p>
      <p>簽證的基本條件是：</p>
      <ul>
        <li>
          申請人年齡小於 <strong>31</strong> 週歲；
        </li>
        <li>過去兩年已經從認可機構完成符合條件工程學位畢業；</li>
        <li>
          英語雅思成績總分 <strong>6</strong> 且每項 <strong>5</strong>{" "}
          分或以上，或成績對等的职業 OET，托福 TOEFL iBT，皮爾遜 PTE，劍橋 CAE
          英語考試；
        </li>
      </ul>
      <ul>
        <li>
          從没持有技能認可畢業生（476 類）或臨時畢業生（485
          類）簽證（除非您是家庭成員）；
        </li>
        <li>符合健康標準，品格和安全的檢查。</li>
      </ul>
      <p>
        您必須在認可的中國教育機構如：北京師範大學，北京石油大學，北京化工大學，北京工業大學（原北京工業大學），中國礦業大學（北京），廣州大學，上海工程大學，上海交通大學，同濟大學，清華大學和北京科技大學，完成學習並畢業於這些工程學歷之一：學士學
        位、碩士、博士學位、或研究生文憑。
      </p>
      <p>
        您學歷必須包含主要學習科技工程專業如：土木工程、結構工程、化學工程、環境工程、電氣和電子工程、機械工程，生產和工廠工程、採礦和材料工程。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="技能認可畢業簽證 (476) Skilled &ndash; Recognised Graduate"
        subHeader="Subclass 476 Skilled—Recognised Graduate visa"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
        <SkilledVisaMatrix></SkilledVisaMatrix>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
