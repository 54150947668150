import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import './studentVisa.css';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
});

export default function StudentVisaPage() {
  const classes = useStyles();

  return (
    <div class="studentVisa">
      <ContentPage header="留学生移民" subHeader="STUDENT VISA">
        <Card className={classes.root}>
          <CardContent>
            <h1>澳洲留學</h1>
            <p>
              澳大利亞簡化留學簽證申請，開放小學教育。中國學生申請澳大利亞留學，需要符合以下基本條件：
            </p>
            <ul>
              <li>滿足澳大利亞學校的入學要求；</li>
              <li>有經濟能力支付赴澳學習所需的費用；</li>
              <li>
                具有良好的英語能力（A 类雅思成绩）或就讀英語配套課程；
                就讀高二以下課程無須英語成績；
              </li>
              <li>身體健康，品行良好；</li>
              <li>通過留学真实資格審查。</li>
            </ul>
            <h3>一、滿足澳大利亞學院的入學要求</h3>
            <p>
              <strong>小學課程</strong>，學生 6 歲（私校）1-6
              年级课程，申請就讀相當于澳大利亞小學教育（公校）4 - 6
              年级課程（須有父母一方陪同學生）。
            </p>
            <p>
              <strong>中學課程</strong>，學生 12
              歲或以上可申請就讀相當于初一或以上的澳大利亞
            </p>
            <p>
              中學校教育 <strong>7</strong>
              <strong> - </strong>
              <strong>12</strong> 年级課程。在入讀中學主課程前必須就讀英語配套課
            </p>
            <p>
              程不少於 20
              週。高中二年級畢業學生可考慮直接申請报讀職業與技術教育學校（TAFE）或專科學院（College）。首次申請澳大利亞中學課程的要求：
            </p>
            <ul>
              <li>申請者成功完成了 6 年或同等學歷教育；</li>
              <li>
                年齡適合您報讀的年級須符合下列之一：
                <ul>
                  <li>中學課程將持續至少 16 個月；</li>
                  <li>為期兩年符合澳大利亞中學校教育且英語言语授課。</li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>專科院校</strong>，又稱&ldquo;技術與繼續教育學院&rdquo;（
              TAFE
              ），提供職業培訓，商業，技術等方面的課程，其頒發的文憑包括兩年制或三年制大專文憑。學校要求申請者已受過
              12 年（高中毕業）教育。
            </p>
            <p>
              <strong>高等院校</strong>
              （含大學）所設課程涉及各種學術及職業領域，入學要求根
              據獨立學院決定。如科學，計算機，管理，商業，貿易，工程，法律，
              醫學等，读本科雅思成绩 6 分，读研究生文憑和碩士雅思成绩 6.5 分，
              或成績相等的 OET，托福，PTE，CAE 英語考試。申請攻讀：
            </p>
            <p>a) 大學預科，學制一年或一年半</p>
            <p>&nbsp; &nbsp; 要求申請者入學前受過十二年以上教育。</p>
            <p>b) 學士學位，學制三至五年</p>
            <p>
              &nbsp; &nbsp;
              要求申請者在中國高考成績達到所讀大學分數線，或完成專科文憑或大學學習一年，或在澳洲完成預科或專科文憑。
            </p>
            <p>c) 研究生文憑，學制半年或一年</p>
            <p>&nbsp; &nbsp; 要求申請者入學前已獲得大專毕業或大學士學位。</p>
            <p>d) 碩士學位，學制十八個月至兩年</p>
            <p>&nbsp; &nbsp; 學校要求申請者已獲得學士學位。</p>
            <p>e) 博士學位，學制三至五年</p>
            <p>&nbsp; &nbsp; 學校要求申請者已獲得碩士學位。</p>
            <h3>二、有經濟能力支付赴澳學習所需的費用</h3>
            <p>
              申請人必須預交全额英文配套课程学费，及半年以上或学校允许的主课程學費的定金额，並能夠提供在澳大利亞留學期間的一切費用來源的證明，這包括學費，生活及往返機票。
            </p>
            <h3>三、具備良好的英語能力</h3>
            <p>
              除了高中以下（含高中在內）課程和配套課程之外，澳大利亞政府要求申請學習私立商科、技術與繼續教育、大學預科、學士學位雅思成績
              5.5
              或以上，申請學習研究生文憑課程、或碩士學位課程者雅斯成績6.5以上，申請學習碩士研究以上學位則根據學校的要求，沒有一個固定的分數線，一般雅思在
              6
              分以上。醫學護理，健康，法律，教師，社工等行業相關的專業，其英語水平要求相等與雅思
              7 分或以上。
            </p>
            <p>
              盡管如此，申請簽證時要求雅思 IELTS 成績總分 <strong>4.5</strong>{" "}
              分且每項 4
              分以上，否則無法得到簽證。移民局同時承認接納對等成績的職業 OET，
              托福 TOEFL iBT，皮爾遜 PTE，劍橋 CAE 的學術類英語考試。
            </p>
            <h3>四、身體健康，品行良好</h3>
            <p>身體健康，沒有肺結核等嚴重傳染病， 沒有刑事犯罪記錄。</p>
            <h3>五、學生未滿 18歲</h3>
            <p>
              要想獲得學生簽證，申請人必須證明由學校安排与學生簽證長度直至他們年滿
              18
              歲周歲的福利安置。他們可以證明會由父母或合法監護人，一個適當的相對或學生的教育機構批准對學生的家庭住宿，支持和一般福利的安排。這是相關機構發出確認完成適當適當的住宿和福利（CAAW）信函。
            </p>
            <h3>六、留学真實資格审核（Genuine Temporary Entrant-GTE）</h3>
            <p>
              學生要求在遞交簽證申請前，先註冊即繳交第一期學費，學校給了註冊的確認信（COE），然後遞交網上簽證申請。澳洲留學簽證申請其中重要環節是須通過真實性審核。主要考慮以下幾個方面的因素：
            </p>
            <ul>
              <li>您來澳洲留學的具體情況；</li>
              <li>您過去澳洲簽證申請及澳洲出入境移民史；</li>
              <li>您學成之後回國是否已有安排而且是可信的；</li>
              <li>您的課程和您未來就業前景的關系；</li>
              <li>您的英語程度和主課程入學英文要求的差距；</li>
              <li>您是否有足夠資金支付澳洲學費生活費等；</li>
              <li>您年齡未滿 18 歲的父母法定監護人或您配偶申請人的意圖；</li>
              <li>您任何其他有關事宜。</li>
            </ul>

            <h1>職業技術教育</h1>
            <p>
              澳大利亞職業與技術教育體系爲學生提供了廣泛的學習機會，旨在滿足當今各行業對員工的實際需要。澳大利亞職業與技術教育符合國際標准，有政府公立职业教育学校（TAFE）或企业及公司经学校等遍及澳大利亞各州或领地。
            </p>
            <p>澳大利亞的職業教育體系（TAFE）層次分：</p>
            <ol>
              <li>專業證書一、二、三和四；</li>
              <li>大專文憑或高级大專文憑。</li>
            </ol>
            <p>
              澳大利亞職業與技術教育文凭是二年制。部分職業技術教育必須入读讀證書
              3 或 4
              的課程，然後繼續大專文憑課程。證書課程大部分是一年，完成後有些職業已經符合條件申請職業技術評估或資格證書。大專文憑課程有
              6 個月、一年或二年，按不同職業而定。
            </p>
            <h3>職業技術課程入學要求</h3>
            <ol>
              <li>高中二或相等于高中二或以上毕业；</li>
              <li>
                雅思成绩平均達 <strong>5.5</strong> 分。
              </li>
            </ol>
            <p>
              <strong>說明</strong>：<u>學生最低的英語語言能力是雅思 </u>
              <u>IELTS</u> <u>成績總分</u> <u>4.5</u> <u>且每項</u> <u>4</u>{" "}
              <u>分</u> <u>或以上，或成績對等的職業 </u>
              <u>OET</u>
              <u>，托福 </u>
              <u>TOEFL iBT</u>
              <u>，皮爾遜 </u>
              <u>PTE</u>
              <u>，劍</u>
              <u>橋 </u>
              <u>CAE</u>{" "}
              <u>的學術類英語考試。诺您英語水平低於課程入學要求的分數，</u>{" "}
              <u>
                可申請相應週数期限的英語語言配套課程，先讀語言课，考試及格了再進入主課就讀
              </u>
              。
            </p>
            <p>&nbsp;</p>
            <h1>語言英文課程</h1>
            <p>
              中國學生可單獨申請讀語言英文課程。不需要提供雅思成績。在就讀期間，如雅思成績達到其他教育課程入學的要求，隨時可轉讀其他移民課程。陪讀家長
              <u>在持有效監護簽證期間，可隨時報讀語言課程</u>。
            </p>
            <p>&nbsp;</p>
            <h1>申請留學類程</h1>
            <ul>
              <li>申請學校學位</li>
              <li>獲得學校學位錄取信</li>
              <li>接受錄取學位</li>
              <li>交附首期學費和語言英文培訓費</li>
              <li>學校註冊確認信</li>
              <li>遞交學生簽證申請</li>
              <li>學生簽證獲批</li>
              <li>入境澳大利亞</li>
              <li>學校報到</li>
            </ul>
            <p>
              <strong>注：</strong>學生簽證申請整個過程通常需 2-4
              個月（每個案例不盡相同）。
            </p>

            <h4>留學費用參考表（澳大利亞幣）</h4>
            <dl>
              <dd>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td>
                        <p>課程</p>
                      </td>
                      <td>
                        <p>學費（萬元/年）</p>
                      </td>
                      <td>
                        <p>生活費（元/年）</p>
                      </td>
                      <td>
                        <p>醫療保險費（元/年）</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>本科或碩士</p>
                      </td>
                      <td>
                        <p>2.5 &ndash;5.0</p>
                      </td>
                      <td>
                        <p>21,041</p>
                      </td>
                      <td>
                        <p>600-800</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>職業與技術</p>
                      </td>
                      <td>
                        <p>2.0&ndash;3.0</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>公立中學</p>
                      </td>
                      <td>
                        <p>1.5-1.8</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>私立中學</p>
                      </td>
                      <td>
                        <p>2.8-4.5</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>公立小學</p>
                      </td>
                      <td>
                        <p>1.2-1.5</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>私立小學</p>
                      </td>
                      <td>
                        <p>1.8-2.5</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                      <td>
                        <p>同上</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>
            <p>
              <b>注：</b>中小學入讀主課前配套英文語言培訓 22週學費約 8,900澳元。
            </p>
          </CardContent>
        </Card>
      </ContentPage>
    </div>
  );
}
