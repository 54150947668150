import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import SkilledMigrationPR from "../skilledMigration/skilledMigrationPR";
import SkilledMigrationTR from "../skilledMigration/skilledMigrationTR";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function SkilledMigrationPage() {
  const classes = useStyles();

  return (
    <>
      <ContentPage header="技术移民" subHeader="SKILLED VISA">
        <Card className={classes.root}>
          <h1><a href="/skilled_visa_pr">澳洲技術類移民（永居）</a></h1>
          <SkilledMigrationPR hideHeader></SkilledMigrationPR>
          <hr></hr>

          <h1><a href="/skilled_visa_tr">澳洲技術移民（臨居）</a></h1>
          <SkilledMigrationTR hideHeader></SkilledMigrationTR>
          <hr></hr>

        </Card>
      </ContentPage>
    </>
  );
}
