import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import Visa_188 from "../bussinessInnovation/visa_188";
import Visa_888 from "../bussinessInnovation/visa_888";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function BusinessInnovationAndInvestmentPage() {
  const classes = useStyles();

  return (
    <>
      <ContentPage
        header="商业投资移民"
        subHeader="BUSINESS INNOVATION AND INVESTMENT"
      >
        <Card className={classes.root}>
          <Typography>
            <h1>澳洲商業移民</h1>
            <p>
              澳大利亞政府商業創新投資移民類別项目 2021 年 7 月
              1日改進，成功申請商業創新和投資移民者將先獲臨時簽證有效
              5年。在證明具有創新經營和 或合規的投資活動
              3年後，如果滿足所有要求則可申請成爲永久居民。
            </p>
            <Visa_188 hideHeader></Visa_188>
            <Visa_888 hideHeader></Visa_888>
          </Typography>
        </Card>
      </ContentPage>
    </>
  );
}
