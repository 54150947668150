import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Link } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function InformationCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <Card className={classes.root}>
      <CardHeader title="澳洲资讯" subheader="INFORMATION"></CardHeader>

      <CardContent>
        <Link href="/About">
          <Typography variant="h7">
            澳大利亚的教育体制
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
            澳大利亚高考和大学招生概况
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
          澳大利亚的社会福利
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
          澳洲的医疗保健系统
            <br></br>
          </Typography>
        </Link>

        <Link href="/About">
          <Typography variant="h7">
          租房提示
            <br></br>
          </Typography>
        </Link>

        <div style={{ display: "flex" }}>
            <Link href="/About" style={{ marginLeft: "auto" }}>
              <Typography variant="h7">
                ... 更多关于澳洲的信息
                <br></br>
              </Typography>
            </Link>
        </div>
      </CardContent>
    </Card>
  );
}
