import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import img from '../../assets/advantage1.jpg';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  media: {
    height: 260,
  },
});

export default function CoreAdvantageCard1() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={img}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            移民留学双强项
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          留学部由澳洲博士，教育专家赵博士主理。赵博士十多年前获得澳洲博士学位，曾在昆士兰大学，莫纳什大学，格里菲斯大学和卧龙岗大学等澳洲著名大学工作，对澳洲的教育制度有较深入的研究。同时，赵博士精通留学签证的各种要求和留学移民政策的最新动向，是您顺利获得留学签证，实现留学+移民澳洲梦想的信心保证。由赵博士带领的留学教育专家团队，竭诚为您精心设计留学+移民方案。
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
