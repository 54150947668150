import { Typography } from "@material-ui/core";
import React from "react";
import ContentPage from "./contentPage";
import map from "../assets/map.jpg";

export default function contactUs() {
  return (
    <>
      <ContentPage header="联系我们" subHeader="CONTACT US">
        <Typography>
          <table width="95%" align="center">
            <tbody>
              <tr>
                <td style={{ verticalAlign: "centre" }}>
                  地址：Suite 202, Eastwood Centre Office Tower<p></p>160 Rowe
                  Street, Eastwood, NSW 2122<p></p>
                  电话： 61 2 8065 0864<p></p>
                  传真： 61 2 8084 1629 <p></p>
                  手机： 61 432 827 628 <p></p>
                  电邮： info@pengsun.net.au<p></p>
                </td>
                <td width="200">
                  <img src={map} style={{ width: "100%" }}></img>
                  <p>公司位置图（澳洲）</p>
                </td>
              </tr>
            </tbody>
          </table>
        </Typography>
      </ContentPage>
    </>
  );
}
