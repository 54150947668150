import { Divider, Grid } from '@material-ui/core'
import React from 'react'
import AnnouncementCard from '../components/cards/announcement'
import NewsCard from '../components/cards/news'
import InformationCard from '../components/cards/information'
import StudyAbroadCard from '../components/cards/studyAbroad'
import ImageSlider from '../components/imageSlider'
import CoreAdvantage from '../components/coreAdvantage'

export default function homePage() {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <ImageSlider></ImageSlider>
                </Grid>
                <Grid item xs={2}>
                    {/* Empty layout */}
                </Grid>

                <Grid item xs={12} sm={8}>
                    {/* Row 1 */}
                    <Divider variant="middle" />
                    <Grid container spacing={3} justify="center" alignItems="flex-start">
                        <Grid item xs={10} sm={7}>
                            <NewsCard></NewsCard>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            <StudyAbroadCard></StudyAbroadCard>
                        </Grid>
                    </Grid>

                    {/* Row 2 */}
                    <br></br>
                    <Divider variant="middle" />
                    <Grid container spacing={3} justify="center" alignItems="flex-start">
                        <Grid item xs={10} sm={7}>
                                <AnnouncementCard></AnnouncementCard>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            <InformationCard></InformationCard>
                        </Grid>
                    </Grid>   

                    {/* Row 3 */}
                    <br></br>
                    <Divider variant="middle" />
                    <Grid container>
                        <Grid item xs={12}>
                            <CoreAdvantage></CoreAdvantage>
                        </Grid>
                    </Grid>
                    <br></br>


                    {/* End Row */}
                    <br></br>
                </Grid>

                <Grid item sm={2}>
                    {/* Empty layout */}
                </Grid>
            </Grid>
        </>
    )
}
