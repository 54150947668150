import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Visa_186 from "./visa_186";
import Visa_858 from "./visa_858";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
});

export default function EmployerSponsoredPR(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const content = () => (
    <>
      <Visa_186 hideHeader></Visa_186>
      <Visa_858 hideHeader></Visa_858>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="雇主提名移民（永居）"
        subHeader="Employer Nomination Scheme Visa"
      >
        <Card className={classes.root}>
          <h1>雇主提名移民（永居）</h1>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
