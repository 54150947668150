import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Link } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%'
  },
  link: {
    marginLeft: '2px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function NewsCard() {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="最新信息" subheader="IMMIGRATION NEWS"></CardHeader>

      <CardContent>
        <Link className={classes.link} href="/About">
          <Typography variant="h7">
            商業創新和投資（臨居-188类）評分錶
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
            商业移民签证改革
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
            澳洲將再次調整574留學簽證審核級別
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳洲政府增移民配额至18.5万人：增幅9.7%
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳大利亚将启动意向表达计划
            <br></br>
          </Typography>
        </Link>

        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳大利亞留學新政策11月5日正式實施
            <br></br>
          </Typography>
        </Link>
        
        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          新州改革商业移民计划
            <br></br>
          </Typography>
        </Link>
        
        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳大利亚将简化留学签证要求
            <br></br>
          </Typography>
        </Link>
        
        <Link className={classes.link} href="/About">
          <Typography variant="h7">
          澳大利亞政府新設海外學生申訴專員
            <br></br>
          </Typography>
        </Link>

        <div style={{ display: "flex" }}>
            <Link className={classes.link} href="/About" style={{ marginLeft: "auto" }}>
              <Typography variant="h7">
                ... 更多最新信息
                <br></br>
              </Typography>
            </Link>
        </div>
      </CardContent>
    </Card>
  );
}
