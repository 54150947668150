import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Grid, Link } from '@material-ui/core';
import CoreAdvantageCard1 from './cards/coreAdvantage1';
import CoreAdvantageCard3 from './cards/coreAdvantage3';
import CoreAdvantageCard2 from './cards/coreAdvantage2';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%'
  },
  header: {
    textAlign: "center",
  }
});

export default function CoreAdvantage() {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header} title="核心优势" subheader="CORE STRENGTHS"></CardHeader>

      <CardContent>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <CoreAdvantageCard1></CoreAdvantageCard1>
            </Grid>

            <Grid item xs={12} sm={4}>
                <CoreAdvantageCard2></CoreAdvantageCard2>
            </Grid>

            <Grid item xs={12} sm={4}>
                <CoreAdvantageCard3></CoreAdvantageCard3>
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
