import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "./skilled_visa_matrix";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
});

export default function Visa_485(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_485">
              留學畢業工作簽證 (485) Graduate or Post&ndash;Study Work
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/skilled_visa_tr">澳洲技術移民（臨居）</a>
          </h1>
          <h3>留學畢業工作簽證 (485) Graduate or Post&ndash;Study Work</h3>
        </>
      );
    }
  };
  const content = () => (
    <>
      {header()}
      <p>
        專爲符合在澳大利亞留學的畢業生而設的工作簽證，無需測試計分，無學習或工作條件限制。簽證持有人隨時可根據的實際條件通過其他途徑申請轉永居或臨時技術工作簽證或雇主擔保工作簽證。簽證的基本條件是：
      </p>
      <ul>
        <li>申請人年齡必须是 18 至 44 週歲内；</li>
        <li>
          具英語雅思成績總分 <strong>6</strong> 且每項 <strong>5</strong>{" "}
          分或以上，或成績對等的职業 OET，托福 TOEFL iBT，皮爾遜 PTE，劍橋 CAE
          英語考試；
        </li>
      </ul>
      <ul>
        <li>遞交申請前過去 6 個月內完成在澳大利亞課程學習畢業；</li>
        <li>
          完成课程滿足兩年學習時間的要求，必須同時滿足下列條件：
          <ul>
            <li>所學課程是聯邦註冊機構（CRICOS）登記的課程；</li>
            <li>完成至少二個學術年（92 周）或 16 個自然月的學習；</li>
            <li>該學習用英語授課。</li>
            <li>完成多個課程的學歷的專業必須是相同或密切關聯；</li>
          </ul>
        </li>
        <li>在澳大利亞境內完成學習期間持有允許學習的簽證；</li>
        <li>有澳洲允许工作的医疗保险及提交了澳洲無犯罪證明申請。</li>
      </ul>
      <p>
        <strong>A) 技術畢業生工作 </strong>Graduate Work，簽證有效期 18
        個月。您的提名职業必须在有效的移民技術职業表（MLTSSL）中並與您完成學習的專業密切關聯且申请獲通过了技術职業評估。
      </p>
      <p>
        <strong>B) 畢業生工作允許 </strong>Post-Study
        Work，簽證有效期二（2）至四（4）
        年。申請人在过去十年内首次獲學生簽證入境澳澳大利亞，並完成不少於二（2）年的任何專業本科或以上學歷的學習。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="留學畢業工作簽證 (485) Graduate or Post&ndash;Study Work"
        subHeader="Temporary Graduate visa - Subclass 485"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
        <SkilledVisaMatrix></SkilledVisaMatrix>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
