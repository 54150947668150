import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './imageSlider.css';
import img1 from '../assets/slider/1.png';
import img2 from '../assets/slider/2.png';
import img3 from '../assets/slider/3.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  image: {
    height: '100%',
    width: '100%'
  },
}));

const ImageSlider = () => {
  const classes = useStyles();

    return (
      <div className="container">
        <Slide easing="ease">
          <div className="each-slide">
            <img className={classes.image} src={img1}></img>
          </div>
          <div className="each-slide">
            <img className={classes.image} src={img2}></img>
          </div>
          <div className="each-slide">
            <img className={classes.image} src={img3}></img>
          </div>
        </Slide>
      </div>
    )
};

export default ImageSlider;