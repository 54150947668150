import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import Visa_partner from "../familyReunion/partner_visa";
import Visa_parent from "../familyReunion/parent_visa";
import Visa_child from "../familyReunion/child_visa";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function FamilyReunionPage() {
  const classes = useStyles();

  return (
    <>
      <ContentPage header="家庭团聚移民" subHeader="FAMILY REUNION VISA">
        <Card className={classes.root}>
          <CardContent>
          <h1>親屬類移民（家庭團聚）</h1>
          <Visa_partner hideHeader></Visa_partner>
          <hr></hr>
          <Visa_parent hideHeader></Visa_parent>
          <hr></hr>
          <Visa_child hideHeader></Visa_child>
          <hr></hr>
          </CardContent>
        </Card>
      </ContentPage>
    </>
  );
}
