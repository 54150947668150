import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
  },
});

export default function Visa_partner(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_partner">配偶或情侶移民</a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/familyReunion">親屬類移民（家庭團聚）</a>
          </h1>
          <h3>配偶或情侶移民</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        申請人的配偶（包括已經結婚，即將來澳预期結婚的未婚情侶，異
        性或同性已同居相互依賴的情侶）是澳大利亞公民或永久居民，不能自動成為澳大利亞永久居民。他們必須提出申請並且符合澳大利亞移民
        法。規定的準則才能獲得簽證。
      </p>
      <p>申請人可申請以下其中一種配偶或情侶移民類別：</p>
      <ol>
        <li>
          <p>預期結婚簽證 Prospective Marriage（300）</p>
        </li>
        <li>
          <p>結婚或實情婚姻簽證 Partner (marriage or de facto)：</p>
          <ul>
            <li>
              <p>
                在澳洲境外提交申請臨居转永居（309<strong>-</strong>100）
              </p>
            </li>
            <li>
              <p>
                在澳洲內提交臨居转永居（820<strong>-</strong>801）
              </p>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        無論是已經婚的配偶移民，或來澳预期結婚的的未婚伴侶移民，和
        異性或同性已同居相互依賴伴侶移民的申請人，必須能證明:
      </p>
      <ul>
        <li>
          <p>相互承諾以對方爲唯一伴侶共同生活 ；</p>
        </li>
        <li>
          <p>雙方的關係是真實和持續的；</p>
        </li>
        <li>
          <p>雙方共同生活或者不是長期分居；</p>
        </li>
        <li>
          <p>雙方共同承擔分享家庭經濟收入居住所需的支出消費；</p>
        </li>
        <li>
          <p>
            预期結婚申請人必須能證明雙方經過會面且申請人了解澳方未婚夫(妻)的個人情況，並有以配偶身份共同生活的真實意願。
          </p>
        </li>
        <li>
          <p>相互依賴的伴侶包括但不僅限於同性伴侶，必須居住在一起達</p>
        </li>
      </ul>
      <p>12 個月以上、或在澳大利亞持有情侶關係註冊證明。</p>
      <p>
        申請人仍須提供足夠的材料去證明這段關系是真實延續的，<u>否則將</u>
        <u>容易導致申請失敗被拒簽</u>。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage header="配偶或情侶移民" subHeader="Partner Visa">
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
