import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "./skilled_visa_matrix";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function Visa_190(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_190">
            擔保技術簽證 (190) Skilled &ndash; Nominated
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/skilled_visa_pr">澳洲技術移民（永居）</a>
          </h1>
          <h3>擔保技術簽證 (190) Skilled &ndash; Nominated</h3>
        </>
      );
    }
  };

  const content = () => (
    <>
      {header()}
      <p>
        適合在有效移民技術职業表（MLTSSL 或
        STSOL），同时在您选择的州/地區政府本地區技術職業緊缺名單，獲認可的提名职業評估的人士，符合上述技術類移民基本的條件而測試計分僅
        60，但願意在擔保的州/地區政府管轄地區定居的人員。必須獲得选择的州/地區政府的擔保（加
        5 分）。參考《澳大利亞技術移民計分测试標準表》。
      </p>
      <p>
        每個州/地區政府有本地區的技術職業緊缺名單，除職業短缺重要考慮外，候選人的測試總分數，英文能力，工作經驗，本地區长时间居
        住、留學、或技術工作（或者伴侶），獲有本地區雇主與提名職業相關職位的聘用合同書等，是擇優選取的因素。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="獨立技術簽證 (190) Skilled – Independent"
        subHeader="Skilled Independent visa (subclass 190)"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
          <SkilledVisaMatrix></SkilledVisaMatrix>
        </Card>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
