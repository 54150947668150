import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import img from '../../assets/advantage2.jpg';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  media: {
    height: 260,
  },
});

export default function CoreAdvantageCard2() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={img}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            澳洲当地中介
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          鹏迅移民顾问是一支由澳洲资深注册移民代理、投资专家、会计师等专业人士构成的精英队伍。我们的移民代理和专业顾问有权直接进入澳大利亚移民局的移民法、移民条例和移民政策数据库查询有关移民留学政策的详细资料和最新动向，为广大客户提供第一手的准确资料。助您实现移民澳洲的梦想。
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
