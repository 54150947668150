import React from 'react'
import Footer from 'rc-footer';
import './footer.css';
import logo from '../assets/logo.png';
import icon from '../assets/icon.png';

export default function footer() {
    return (
        <Footer
            columns={
                [
                    // 公司介绍
                    {
                        title: '鹏讯移民',
                        items: [
                            {
                                title: (<>
                                    <span>鹏迅移民顾问是一支由澳洲资深注册移民代理，<br></br></span>
                                    <span>投资专家、会计师等专业人士构成的精英队伍。<br></br></span>
                                    <span>“诚信、专业、快捷、信誉为本、客户至上”<br></br></span>
                                    <span>是我们一直秉承的办事宗旨。<br></br></span>
                                    <span>高水准的专业服务和贴心完善的后继服务，令您省时放心。<br></br></span>
                                </>)
                            }
                        ],
                    },
                    // 快速链接
                    {
                        title: '快速链接',
                        items: [
                            {
                                title: '首页',
                                url: '#home',
                                openExternal: false,
                            },
                            {
                                title: '签证种类',
                                url: '#home',
                                openExternal: false,
                            },
                            {
                                title: '服务项目',
                                url: '/',
                                openExternal: false,
                            },
                            {
                                title: '关于我们',
                                url: '/About',
                                openExternal: false,
                            },
                            {
                                title: '联系我们',
                                url: '/ContactUs',
                                openExternal: false,
                            }
                        ],
                    },
                    // 联系我们
                    {
                        title: '联系我们',
                        items: [
                            {
                                title: '地址：Suite 202, Eastwood Centre Office Tower 160 Rowe Street, Eastwood, NSW 2122',
                            },
                            {
                                title: '电话：612 8065 0864',
                            },
                            {
                                title: '手机：61 432 827 628',
                            },
                            {
                                title: '邮箱：info@pengsun.net.au',
                            }
                        ],
                    },
                ]
            }
            backgroundColor = '#474747'
            bottom = {(
                <>
                    <span>澳大利亚鹏迅移民留学顾问<br></br></span>
                    <span>澳大利亚工商注册号码（ABN）：54 121 960 450<br></br></span>
                    <span>Australian Pengsun Migration and Education Consultancy</span>
                </>
            )}
      />
    )
}
