import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import SkilledVisaMatrix from "./skilled_visa_matrix";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
});

export default function Visa_491(props) {
  const classes = useStyles();
  const hideHeader = props.hideHeader;
  const header = () => {
    if (hideHeader) {
      return (
        <>
          <h3>
            <a href="/visa_491">
              偏遠地區技術工作簽證 (491) Skilled &ndash; Regional Work
            </a>
          </h3>
        </>
      );
    } else {
      return (
        <>
          <h1>
            <a href="/skilled_visa_tr">澳洲技術移民（臨居）</a>
          </h1>
          <h3>偏遠地區技術工作簽證 (491) Skilled &ndash; Regional Work</h3>
        </>
      );
    }
  };
  const content = () => (
    <>
      {header()}
      <p>
        適合在有效移民技術职業表（MLTSSL 或
        STSOL）具有認可的提名职業評估，符合上述技術類移民的基本條件，而測試計分僅
        50，但願意在擔保的州/地區政府管轄區或親屬擔保人所在地區定居的人員（獲得擔保加
        15 分）。參考《澳大利亞技術移民計分测试標準表》。
      </p>
      <ul>
        <li>州或指定管轄區域及人口低增長城镇地區政府的擔保。</li>
        <li>
          親屬擔保人所在的地區须属澳大利亞指定地區居住满至少二年， 18
          歲以上澳大利亚公民或永久。親屬關係：父母子女、兄弟姐妹、侄（外甥）仔女、孫
          (外孫)子女、嫡親表(堂) 兄弟姐妹。
        </li>
      </ul>
      <p>
        成功的申請人獲五年臨居簽證，在指定偏遠地區或親屬擔保人所在地區居住满並相当于全職工作三（3）年，每年應稅收入達到或超過特定起徵點，可申請技術偏遠地區永居（
        <strong>191</strong>）签证将於 2022 年 11 月 16
        日開啟。该簽證申请時無需擔保無需测试计分。獲永居签证後仍需在指定偏遠地區或親屬擔保人所在地區生活至少二（2）年。
      </p>
    </>
  );

  const contentPage = () => (
    <>
      <ContentPage
        header="偏遠地區技術工作簽證 (491) Skilled &ndash; Regional Work"
        subHeader="Skilled Work Regional (Provisional) visa (subclass 491)"
      >
        <Card className={classes.root}>
          <Typography>{content()}</Typography>
        </Card>
        <SkilledVisaMatrix></SkilledVisaMatrix>
      </ContentPage>
    </>
  );

  return <>{hideHeader ? content() : contentPage()}</>;
}
