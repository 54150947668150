import React from 'react';
import { HashRouter, Router } from 'react-router-dom';
import Routes from './routes/route';
import history from './services/history';
import Header from './components/header';
import Footer from './components/footer';
export default function App() {
    return (
        <>
            <Router history={history}>
                <Header></Header>
                <Routes />
                <Footer></Footer>
            </Router>
        </>
      );
}   