import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import matrix from '../../assets/visa_188_matrix.jpg';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: "100%",
    },
    header: {
        textAlign: "center",
    },
});

export default function Visa_888(props) {
    const classes = useStyles();
    const hideHeader = props.hideHeader;
    const header = () => {
        if (hideHeader) {
          return (
            <>
              <h3>
                <a href="/visa_188">商業創新和投資（永居）（888）</a>
              </h3>
            </>
          );
        } else {
          return (
            <>
              <h1>
                <a href="/businessInnovationAndInvestment">澳洲商業移民</a>
              </h1>
              <h3>商業創新和投資（永居）（888）</h3>
            </>
          );
        }
      };
    const content = () => (
        <>
      {header()}
            <p><b><span>基本要求</span></b></p>
            <ul>
                <li><span>持有商業創新和投資簽證（臨居）</span>188 <span>的申请人；</span></li>
                <li><span>須獲得州或領地政府的擔保邀請</span>, <span>其他与與臨居簽證要求相同。</span></li>
            </ul>
            <p><b><span>商業創新 </span>(Business Innovation)</b></p>
            <ul>
                <li><span>在澳大利亞擁有 </span>1 <span>或 </span>2 <span>個經商註冊號（</span>ABN<span>）生意至少 </span>2 <span>年</span></li>
                <li><span>此期間每季度按時向澳大利亞稅務局申報企業經營活動報告；</span></li>
                <li><span>申請前 </span>12 <span>個月內 </span>1 <span>或 </span>2 <span>個生意營業額不低於 </span><strong>30</strong><span>萬澳幣；</span></li>
                <li><span>擁有至少 </span>1 <span>個自主企業股份以下之一的百分比：</span>
                    <ul>
                        <li>51%<span>和每年營業額低於 </span>40 <span>萬澳幣；或</span></li>
                        <li>30%<span>和每年營業額不低於 </span>40 <span>萬澳幣；</span></li>
                    </ul>
                </li>
                <li><span>購買從非持有商业永居签证的生意且滿足如下其中 </span>2 <span>個條件：</span>
                    <ul>
                        <li><span>生意的總淨資産在申請前 </span>12 <span>個月內不低於 </span>20 <span>萬澳幣；</span></li>
                        <li><span>申請前 </span>12 <span>個月內達個人及企業淨資産至少 </span>60 <span>萬澳幣：</span></li>
                        <li><span>申請前 </span>12 <span>個月內僱傭 </span>2 <span>個非親屬的全職本地人員。</span></li>
                    </ul>
                </li>
                <li><span>申請前二年裡在澳大利亞已住滿一年。</span></li>
            </ul>
            <p><b><span>投資者 </span>(Investor)</b></p>
            <ul>
                <li>維持 4 年 150 萬澳幣州/領地政府指定投資項目如在 2021 年 7 月 1 日前獲臨居簽證，否則 250 萬澳幣合規的投資項目組合如下；
                    <ul>
                        <li>20%風險投資（50 萬澳幣）；</li>
                        <li>30%新興企業（75 萬澳幣）；</li>
                        <li>高達 50% 的平衡投資（125 萬澳幣）；和<br />持有臨居簽證期间裡已在澳大利亞居住滿 2 年；</li>
                    </ul>
                </li>
            </ul>
            <p><b><span>重大投資者 </span>(Significant-Investor)</b></p>
            <ul>
                <li>已經維持在臨居簽證期間裡至少 500 萬澳幣合規的投資項目：
                    <ul>
                        <li>10%（50 萬澳幣）/20%風險投資（100 萬澳幣）</li>
                        <li>30%新興企業（150 萬澳幣）</li>
                        <li>高達 60%（300 萬澳幣）/50% 的平衡投資（250 萬澳幣）；</li>
                        <li>持臨居簽證期间您在澳大利亞平均每年居住 40 天或您配偶持臨居<br />簽證平均每年居住 180 天。</li>
                    </ul>
                </li>
            </ul>
            <ol></ol>
            <p><b><span>企業家 </span>(Entrepreneur)</b></p>
            <ul>
                <li><span>須證明持臨居期间在澳大利亞持續創業活動總體成功的記錄；</span></li>
                <li>2<span>個關鍵成功因素，或 </span>1 <span>個關鍵成功因素和 </span>3 <span>個支持成功因素；</span></li>
                <li><span>關鍵成功因素包括：</span>
                    <ul>
                        <li><span>僱用 </span>2 <span>名或更多澳大利亞居民或其他符合條件的員工；</span></li>
                        <li><span>業務年營業額達 </span>30 <span>萬澳幣或更高；</span></li>
                        <li><span>申請臨時專利或獲得註册商標或創新專利； </span></li>
                        <li><span>在您的企業家活動中獲得持續的資金或投資； </span></li>
                        <li><span>與大學建立合作夥伴關係；</span></li>
                        <li><span>以 </span>200 <span>萬澳元的價格出售一家創業公司。</span></li>
                    </ul>
                </li>
                <li><span>支持成功的因素包括：使您的創業活動適應其他業務領域，獲州或領地政府提名人的成功聲明，獲得企業部門的讚助，開辦 </span>1 <span>項其他業務或為 </span>2 <span>項其他業務做出貢獻，獲得正式獎勵或認可，獲籌集或貢獻社會資本。</span></li>
                <li><span>申請前在澳大利亞已住滿 </span>2 <span>年。</span></li>
            </ul>
            <p><span><span><span lang="en-US"><span></span></span></span></span></p>
            <ol></ol>
        </>
    );
    const contentWithHeader = () => (
        <>
            <ContentPage
                header="商業創新和投資（永居）（888）"
                subHeader="Business Innovation and Investment (Permanent) visa (subclass 888)"
            >
                <Card className={classes.root}>
                    <Typography>
                        {content()}
                    </Typography>
                </Card>

            </ContentPage>
        </>
    );
    return (
        <>{hideHeader ? content() : contentWithHeader()}</>
    );
}
