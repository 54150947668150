import { Grid } from '@material-ui/core';
import React from 'react'
import bannerImage from '../assets/banner.png';
// import bannerImage from '../assets/banner.jpg';
import './contentPage.css';

// params: header, subHeader
export default function ContentPage(props) {
    return (
        <>
            <div class="box">
                <img src={bannerImage} style={{width: '100%', height: 200}}></img>
                <div class="text">
                    <h2 style={{marginBottom: -20}}>{props.subHeader}</h2>
                    <h1>{props.header}</h1>
                </div>
            </div>

            <Grid container>
                <Grid item xs={3}>
                    {/* Empty layout */}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {/* Actual children page content */}
                    {props.children}   
                </Grid>

                <Grid item xs={3}>
                    {/* Empty layout */}
                </Grid>
            </Grid>
        </>
    )
}
