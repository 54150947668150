import React from "react";
import ContentPage from "../contentPage";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Divider, Grid, Link } from "@material-ui/core";
import EmployerSponsoredPR from "../employerSponsored/employer_visa_pr";
import EmployerSponsoredTR from "../employerSponsored/employer_visa_tr";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
});

export default function EmployerSponsoredPage() {
  const classes = useStyles();

  return (
    <>
      <ContentPage header="雇主提名移民" subHeader="EMPLOYER SPONSORED VISA">
        <Card className={classes.root}>
          <CardContent>
          <h1><a href="/employer_visa_pr">雇主提名移民（永居）</a></h1>
           <EmployerSponsoredPR hideHeader></EmployerSponsoredPR>
           <hr></hr>
          <h1><a href="/employer_visa_tr">雇主提名移民（臨居）</a></h1>
           <EmployerSponsoredTR hideHeader></EmployerSponsoredTR>
           <hr></hr>
          </CardContent>
        </Card>
      </ContentPage>
    </>
  );
}
